export default {
  "header": {
    "txt_inicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "txt_carta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "txt_cobertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage"])},
    "txt_beneficios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefits"])},
    "txt_ingresar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "txt_iniciar_sesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "txt_mi_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])}
  },
  "footer": {
    "txt_nuestra_cobertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Coverage"])},
    "txt_trabaja_con_nosotros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with Us"])},
    "txt_terminos_y_condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "txt_siguenos_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us on:"])},
    "txt_desarrollador_por": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developed by"])}
  },
  "modal": {
    "txt_iniciar_sesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "txt_registrarse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "txt_olvidaste_tu_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "txt_recuperala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover it"])},
    "txt_o": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "txt_ingresar_con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with"])},
    "txt_he_leido_y_aceptop_los_terminos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and accept the terms, conditions, policies, and personal data treatment"])},
    "txt_he_leido_y_aceptop_los": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and accept the"])},
    "txt_terminos_y_condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "txt_y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "txt_politicas_y_tratamiento_de_datos_personales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policies and Personal Data Treatment"])},
    "txt_si_tuvieras_inconvenientes_camino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you encounter any inconveniences on the way to the restaurant, let us know to delay your order"])},
    "txt_si_tuvieras_inconvenientes_camino_reprogramar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you encounter any inconveniences on the way to the restaurant, let us know to reschedule your reservation."])},
    "txt_deseas_confirmar_tu_compra": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to confirm your purchase for the address ", _interpolate(_named("direction")), "?"])},
    "txt_felicidades_estimado_cliente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations dear customer! Would you like to redeem your points?"])},
    "txt_no_tienes_suficientes_puntos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have enough points :("])},
    "txt_sigue_acumulando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep accumulating!"])},
    "txt_quiero_recibir_notificaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to receive notifications about the status of my order via Whatsapp."])}
  },
  "toast": {
    "txt_bienvenido_full_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome ", _interpolate(_named("fullName"))])},
    "txt_bienvenido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "txt_error_al_iniciar_sesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error logging in"])},
    "txt_debe_ingresar_su_nombre_y_apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter your first and last name"])},
    "txt_por_favor_ingresa_tu_numero_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your cell phone number"])},
    "txt_tu_celular_debe_tener_9_digitos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cell phone must have 9 digits"])},
    "txt_numero_telefonico_debe_tener_9_digitos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phone number must have 9 digits"])},
    "txt_debe_ingresar_un_dni_o_ce_valido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a valid DNI or CE"])},
    "txt_error_al_intentar_registrarse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while trying to register"])},
    "txt_debe_aceptar_los_terminos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the terms and conditions to continue."])},
    "txt_debe_ingresar_un_correo_valido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a valid email"])},
    "txt_hubo_un_error_con_el_servicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error with the Google Maps service. Please try again, or locate the address on the map."])},
    "txt_ingrese_el_distrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the district"])},
    "txt_ingrese_su_direccion_recuerde_que": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your address. Remember that the delivery person will go to the marker on the map."])},
    "text_direccion_guardada_con_exito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address saved successfully."])},
    "txt_en_este_momento_no_hay_ningun_local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At this time, there are no stores offering delivery."])},
    "txt_debe_ingresar_un_numero_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a valid cell phone number"])},
    "txt_numero_celular_actualizado_con_exito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell phone number updated successfully."])},
    "txt_presentacion_no_disponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentation not available."])},
    "txt_el_producto_no_se_encuentra_disponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product is not available"])},
    "txt_maximo_quantity_productos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum ", _interpolate(_named("quantity")), " products"])},
    "txt_hubo_un_error_al_cargar_la": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error loading the product information"])},
    "txt_el_producto_esta_agotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product is sold out."])},
    "txt_debes_seleccionar_quantity_productos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You must select ", _interpolate(_named("quantity")), " products in the \"", _interpolate(_named("title")), "\" list"])},
    "txt_debes_seleccionar_entre_1_y_quantity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You must select between 1 and ", _interpolate(_named("quantity")), " products in the \"", _interpolate(_named("title")), "\" list"])},
    "txt_agrego_al_carrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added to cart"])},
    "txt_item_actualizado_con_exito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item updated successfully."])},
    "txt_agregado_a_tu_carrito_de_compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added to your shopping cart successfully!"])},
    "txt_hubo_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error"])},
    "txt_tiene_el_maximo_de_productos_escogidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum selected products in the list "])}
  },
  "placeholder": {
    "txt_correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "txt_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "txt_nombre_y_apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First and last name"])},
    "txt_dni_o_ce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID or CE (for points)"])},
    "txt_nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "txt_apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "txt_numero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "txt_numero_de_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell phone number"])},
    "txt_correo_electronico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "txt_dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "txt_fecha_de_nacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "txt_pronombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronoun"])},
    "txt_contrasena_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "txt_nueva_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "txt_confirmar_nueva_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "txt_buscar_por_plato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by dish"])},
    "txt_mostrar_todo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
    "txt_ingresa_una_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter an address"])},
    "txt_ingresa_tu_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your address"])},
    "txt_distrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
    "txt_con_que_nombre_guardara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What name will you save this address under?"])},
    "txt_comentarios_adicionales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional comments"])},
    "txt_fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "txt_horario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule"])},
    "txt_zona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone"])},
    "txt_ingrese_su_cupon_o_codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your coupon or discount code"])},
    "txt_archivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "txt_ingresa_la_contrasena_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the current password"])},
    "txt_ingresa_la_nueva_contasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the new password"])},
    "txt_vuelve_a_ingresar_la_nueva_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter the new password"])},
    "txt_puestos_de_interes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position(s) of interest"])},
    "txt_elija_el_restaurante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the restaurant"])},
    "txt_nombre_completo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "txt_adjunta_tu_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach your CV"])},
    "txt_local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
    "txt_domicilio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "txt_tipo_de_documento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document type"])},
    "txt_numero_de_documento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number"])},
    "txt_telefono_fijo_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landline/Cell phone"])},
    "txt_nombre_padre_madre_tutor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent/Guardian Name"])},
    "txt_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "txt_tipo_de_moneda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency type"])},
    "txt_monto_reclamado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claimed amount"])},
    "txt_detalle_del_producto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product or service detail"])},
    "txt_pedido_del_cliente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer's order"])},
    "txt_ingrese_fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter date"])},
    "txt_ingrese_ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter now"])},
    "txt_razon_social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "txt_ingresar_su_nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your name"])},
    "txt_ingresar_correo_electronico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email address"])},
    "txt_ingrese_su_cupon_o_descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your coupon or discount"])},
    "txt_con_cuanto_vas_a_pagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much will you pay with?"])},
    "txt_seleccione_una_opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an option"])},
    "txt_nombre_y_apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First and last names"])},
    "txt_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellphone"])},
    "txt_canal_de_atencion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention Channel"])},
    "txt_numero_de_pedidos_opcional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number (optional)"])},
    "txt_ingresar_codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter code"])}
  },
  "buttons": {
    "txt_ordenar_ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order now"])},
    "txt_comprar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "txt_guardar_cambios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
    "txt_confirmar_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "txt_recuperar_contrasena_por_correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover password via email"])},
    "txt_ordena_ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order now"])},
    "txt_ordena_aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order here"])},
    "txt_ver_mapa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View map"])},
    "txt_cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "txt_aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "txt_ingresar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "txt_confirmar_la_ubicacion_de_entrega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm delivery location"])},
    "txt_anadir_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add address"])},
    "txt_seguir_comprando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue shopping"])},
    "txt_confirmar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "txt_agregar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "txt_recibir_otro_codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive another code"])},
    "txt_comenzar_ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start now"])},
    "txt_iniciar_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start reservation"])},
    "txt_quiero_registrarme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to register"])},
    "txt_aplicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "txt_borrar_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete coupon"])},
    "txt_continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "txt_modificar_o_cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify or cancel"])},
    "txt_agregar_a_mi_calendario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to my calendar"])},
    "txt_siguiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "txt_cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To close"])},
    "txt_anterior": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "txt_ver_cupones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Coupons"])}
  },
  "home": {
    "txt_nuestra_carta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our menu"])},
    "txt_quieres_estar_al_tanto_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to stay updated on our promotions and benefits?"])},
    "txt_registrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up now!"])},
    "txt_bienvenido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "txt_ya_se_encuentra_registrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are already registered in our website. Thanks for your preference!"])},
    "txt_local_saturado_lo_sentimos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local saturated. We're sorry, we can't take any more orders at the moment."])}
  },
  "carta": {
    "txt_explora_nuestra_carta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore our menu"])}
  },
  "cobertura": {
    "txt_volver_al_inicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
    "txt_nuestra_cobertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our coverage"])},
    "txt_ingresa_tu_dirección_para_encontrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your address to find the nearest location to you."])},
    "txt_usar_ubicacion_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use current location"])},
    "txt_recojo_en_tienda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick up in store"])},
    "txt_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "txt_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
    "txt_telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "txt_horarios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
    "txt_ver_horarios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View hours"])},
    "txt_abierto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "txt_cerrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])}
  },
  "perfil": {
    "txt_volver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "txt_mis_datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My data"])},
    "txt_en_esta_seccion_encontaras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section, you will find your personal data."])},
    "txt_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "txt_si_requieres_modificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you need to modify your password, make sure it is secure."])},
    "txt_direcciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
    "txt_gestionar_tus_direcciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your delivery addresses for your orders."])},
    "txt_anadir_una_direccion_de_entrega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a delivery address"])},
    "txt_mis_pedidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My orders"])},
    "txt_gestion_de_notificaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "txt_pedidos_en_proceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders in progress"])},
    "txt_beneficios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefits"])},
    "txt_conoce_tus_puntos_generador_por": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know your points generated by your purchases and where to redeem them."])},
    "txt_puntos_actuales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current points"])},
    "txt_canjeados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeemed"])},
    "txt_cupones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupons"])},
    "txt_historial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "txt_eliminar_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "txt_cerrar_sesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "txt_hola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "txt_no_regresar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, go back"])},
    "txt_deseas_eliminar_la_direccion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to delete the address ", _interpolate(_named("address")), "?"])},
    "txt_se_elimino_su_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been successfully deleted."])},
    "txt_su_usuario_ya_se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account had already been deleted.  "])}
  },
  "perfilcambiarclave": {
    "txt_correo_para_cambio_de_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset email sent successfully."])},
    "txt_la_contrasena_debe_tener": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must be at least 6 characters long"])},
    "txt_se_cambio_su_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been changed successfully"])}
  },
  "cartarestaurante": {
    "txt_producto_agotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product out of stock"])}
  },
  "perfilmicuentalugares": {
    "txt_direccion_eliminada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address deleted successfully"])}
  },
  "perfilmicuentalugaresaddress": {
    "txt_direcciones_de_entrega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery addresses"])},
    "txt_nubo_un_error_con_el_servicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error with the Google Maps service. Please try again, or manually locate the address on the map."])}
  },
  "perfilmicuentauser": {
    "txt_acumula_puntos_en_todos_los_canales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accumulate points across all channels."])},
    "txt_adquiere_ofertas_exclusivas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get exclusive offers for your day."])},
    "txt_tu_nueva_contrasena_debe_tener": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Your new password must have at least 6 characters."])},
    "txt_se_actualizaron_sus_datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data has been updated successfully."])},
    "txt_hubo_un_error_al_actualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error updating your data."])},
    "txt_nueva_contrasena_debe_ser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The new password must match in both fields. Please verify."])},
    "txt_ella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her"])},
    "txt_el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He"])}
  },
  "perfilmisbeneficiosbeneficios": {
    "txt_expira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires:"])},
    "txt_sin_cupones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No coupons:"])},
    "txt_estimado_cliente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear customer"])},
    "txt_felicitaciones_desea_canjear_sus_puntos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations! Do you want to redeem your points?"])},
    "txt_si_canjear_mi_premio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, redeem my reward"])},
    "txt_se_copio_el_codigo_del_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The coupon code was copied successfully."])},
    "txt_se_activo_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The coupon was activated."])},
    "txt_ocurrio_un_error_al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while activating the coupon."])}
  },
  "perfilmisbeneficioshistorial": {
    "txt_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
    "txt_cupon_delivery_gratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Delivery Coupon"])},
    "txt_de_descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["off"])},
    "txt_sin_historial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No History"])},
    "txt_monto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount"])},
    "txt_porcentaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage"])},
    "txt_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery"])},
    "txt_producto_gratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["free product"])},
    "txt_descuento_fijo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fixed discount on product"])},
    "txt_descuento_porcentual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage discount on product"])},
    "txt_puntos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])}
  },
  "perfilmispedidositem": {
    "txt_pendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "txt_cocinando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooking"])},
    "txt_listo_para_recojo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready for pickup"])},
    "txt_anulado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
    "txt_entregado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
    "txt_mesa_reservada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table reserved"])},
    "txt_en_camino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the way"])}
  },
  "perfilmispedidostab1sp": {
    "txt_conoce_la_carta_personaliza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover the menu, customize your order, and purchase everything in one place!"])}
  },
  "perfilmispedidostab2sp": {
    "txt_historial_vacio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty History"])}
  },
  "perfilmisreservas": {
    "txt_lleva_un_registro_de_todas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep track of all your reservations."])},
    "txt_reservas_programadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled Reservations"])}
  },
  "perfilmisreservastab": {
    "txt_dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "txt_mes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "txt_local_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location:"])},
    "txt_zona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone:"])},
    "txt_cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity:"])},
    "txt_hora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time:"])},
    "txt_codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code:"])},
    "txt_no_hay_reservas_que_mostrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reservations to show"])},
    "txt_no_hay_historial_que_mostrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No history to show"])}
  },
  "componentlogin": {
    "txt_necesitamos_validar_tu_correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We need to validate your email."])},
    "txt_por_tu_seguridad_te_hemos_enviado_un": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For your security, we have sent you an email to validate that it's you. Please check your inbox."])}
  },
  "createcard": {
    "txt_agregar_tarjeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add card"])},
    "txt_numero_de_tarjeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number"])},
    "txt_mes_de_expiracion_mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration month (MM)"])},
    "txt_año_de_expiracion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration year (YYYY)"])},
    "txt_cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
    "txt_correo_electronico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "txt_se_agrego_su_tarjeta_correctamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card was added successfully"])},
    "txt_espere_dat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait..."])},
    "txt_error_al_almacenar_datos_de_tarjeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error storing card data."])},
    "txt_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "txt_fecha_expiracion_mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date (MM)"])},
    "txt_fecha_expiracion_yyyy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date (YYYY)"])},
    "txt_error_al_almacenar_datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error storing your card data."])}
  },
  "dropdownaddress": {
    "txt_direcciones_guardadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved addresses"])},
    "txt_referencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "txt_lo_sentimos_te_encuentras_fuera_de_la": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry, you are outside the delivery area of this restaurant."])},
    "txt_local_saturado_lo_sentimos_no_podemos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant saturated. We're sorry, we cannot accept more orders right now."])},
    "txt_ver_carta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View menu"])},
    "txt_regresar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "txt_local_cerrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant closed"])},
    "txt_si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "txt_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "forgotpassword": {
    "txt_por_favor_ingresa_tu_correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address. We will send you a link to create your new password."])},
    "txt_cargando_dat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "txt_cambiar_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "txt_enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  },
  "messagepay": {
    "txt_resultado_de_la_transaccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Result"])},
    "txt_medio_de_pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method:"])},
    "txt_numero_de_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number:"])},
    "txt_monto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount:"])},
    "txt_moneda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency:"])},
    "txt_numero_de_orden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number:"])},
    "txt_fecha_y_hora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and Time:"])},
    "txt_productos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "txt_descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description:"])},
    "txt_recomendacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendation:"])},
    "txt_por_favor_guarde_la_informacion_mostrada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please save the information shown for your product verification."])},
    "txt_recojo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup"])}
  },
  "modalcancelbooking": {
    "txt_cancelacion_de_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Cancellation"])},
    "txt_se_anulara_su_reserva_y_se_le": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reservation will be canceled, and you will be given a discount code for the value of S/. XX.XX to use on your next purchase."])},
    "txt_cancelar_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Reservation"])},
    "txt_resultado_de_la_transaccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Result"])}
  },
  "modalValidateData": {
    "txt_completar_informacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete information"])},
    "txt_para_continuar_con_el_pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To proceed with the payment, complete the fields"])}
  },
  "modaldelivery": {
    "txt_ubicacion_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Location"])},
    "txt_el_motorizado_ira_a_las_coordenadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driver will go to the coordinates on the map."])},
    "txt_direccion_de_entrega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Address"])},
    "txt_la_direccion_es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address is:"])},
    "txt_casa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "txt_trabajo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])},
    "txt_personalizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize"])},
    "txt_ingresar_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Address"])},
    "txt_no_encuentras_la_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't find the address?"])},
    "txt_tu_direccion_es": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Is your address ", _interpolate(_named("direction")), "?"])}
  },
  "modallistlocals": {
    "txt_local_de_recojo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup Location"])},
    "txt_seleccione_el_local_donde_ira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the location where you will pick up your order."])}
  },
  "modallocalschedules": {
    "txt_local_cerrado_nuestros_horarios_son": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location closed. Our hours are:"])}
  },
  "modalmicarrito": {
    "txt_mi_carrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Cart"])},
    "txt_modalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode:"])},
    "txt_comer_aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eat here"])},
    "txt_subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal:"])},
    "txt_extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras:"])},
    "txt_total_double_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
    "txt_no_hay_productos_en_su_canasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products in your basket"])},
    "txt_explore_nuestra_tienda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore our store"])},
    "txt_confirme_el_tipo_de_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm the type of order you want to place"])},
    "txt_para_llevar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takeaway"])},
    "txt_para_comer_aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For here"])}
  },
  "modalpedirubicacion": {
    "txt_aceptas_que_google_acceda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you accept Google to access your location?"])}
  },
  "modalphoneinput": {
    "txt_ultimo_paso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Step"])},
    "txt_ingresa_tu_numero_de_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your cell phone number to complete your registration."])}
  },
  "modalpredelivery": {
    "txt_necesitamos_tu_direccion_para_asignarte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* We need your address to assign you to a covered location."])}
  },
  "modalproductcustomization": {
    "txt_opcional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
    "txt_deseas_agregar_algo_mas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to add anything else to your order?"])},
    "txt_precio_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base Price:"])}
  },
  "modalqualify": {
    "txt_salir_de_la_calificacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit Rating"])},
    "txt_encuesta_de_satisfaccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfaction Survey"])},
    "txt_como_calificarias_tu_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How would you rate your order on"])},
    "txt_muy_mala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very Poor"])},
    "txt_excelente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent"])},
    "txt_agregar_comentario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Comment"])},
    "txt_que_tanto_te_gustaron_estos_platos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much did you like these dishes?"])},
    "txt_comentario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "txt_recomendarias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you recommend"])},
    "txt_nada_probable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Likely"])},
    "txt_muy_probable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very Likely"])},
    "txt_gracias_por_calificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for rating!"])},
    "txt_tu_calificacion_nos_ayudara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your rating will help us continue to improve."])},
    "txt_eficiencia_de_la_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficiency of the website/app"])},
    "txt_cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "txt_rapidez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed"])},
    "txt_calidad_de_la_comida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food quality"])},
    "txt_precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "txt_servicio_del_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery service"])},
    "txt_buena_presentacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good presentation"])},
    "txt_variedad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variety"])},
    "txt_como_podemos_mejorar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry. How can we improve?"])},
    "txt_aun_falta_una_estrella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're missing one star. What do we need to become excellent?"])},
    "txt_muchas_gracias_que_fue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you very much! What did you like the most?"])},
    "txt_por_favor_debe_seleccionar_alguna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, you must select a star to proceed."])},
    "txt_por_favor_seleccione_alguna_opcion_o_ingrese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, select an option or enter a comment. Thank you!"])},
    "txt_por_favor_califique_el_producto": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please, rate the product ", _interpolate(_named("productName"))])},
    "txt_por_favor_seleccione_una_opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, select an option to indicate if you would recommend us."])},
    "txt_gracias_por_tu_resena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your review!"])},
    "txt_que_nos_falto_para_ser_excelentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What did we lack to be excellent?"])}
  },
  "modalRecentCoupon": {
    "txt_deseas_usar_tu_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to use your coupon"])},
    "txt_en_este_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on this order?"])}
  },
  "modalAddCouponProduct": {
    "txt_deseas_usar_tu_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product will be added "])},
    "txt_en_este_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from the promotion to apply the corresponding discount."])}
  },
  "modalreschedule": {
    "txt_reprogramar_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reschedule Booking"])},
    "txt_la_reprogramacion_sera_disponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rescheduling will be available only once."])},
    "txt_reprogramar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reschedule"])}
  },
  "modalselectcoupon": {
    "txt_podras_ingrsar_o_canjear_tus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can enter or redeem your available coupons."])},
    "txt_canjea_tus_cupones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem your coupons"])}
  },
  "modalsmsvalidation": {
    "txt_verificacion_de_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Verification"])},
    "txt_validar_por_whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate via WhatsApp"])},
    "txt_validar_por_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate via SMS"])},
    "txt_por_favor_ingresa_tu_numero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your mobile number to verify your account"])},
    "txt_mensaje_reenviado_con_exito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message forwarded successfully. "])},
    "txt_ingresa_el_codigo_de_verificacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enter the verification code sent to +51 ", _interpolate(_named("userPhone"))])}
  },
  "modalyapeplin": {
    "txt_paga_con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with"])},
    "txt_a_nombre_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the name of:"])},
    "txt_opcion_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option 1:"])},
    "txt_opcion_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option 2:"])},
    "txt_con_el_numero_de_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with the cell phone number."])},
    "txt_escanea_el_codigo_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code and make the payment."])},
    "txt_al_numero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the number"])},
    "txt_luego": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then:"])},
    "txt_clic_aqui_para_subir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to upload your proof"])},
    "txt_debe_seleccionar_una_imagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select an image with the correct format "])},
    "txt_se_copio_el_numero_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phone number was copied correctly."])},
    "txt_se_creo_su_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order was created successfully"])},
    "txt_procesando_pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing payment"])}
  },
  "paymentlzipay": {
    "txt_cargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "txt_soles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PEN"])},
    "txt_aceptado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "txt_denegado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denied"])},
    "txt_compra_fallida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed purchase"])},
    "txt_en_linea_izipay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izipay online"])},
    "txt_ocurrio_un_problema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An issue occurred. Please try again."])},
    "txt_venta_exitosa_te_notificaremos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful sale. We will notify you of the status of your order."])}
  },
  "selectcard": {
    "txt_seleccionar_tarjeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Card"])}
  },
  "barralateralizquierda": {
    "txt_deseas_cerrar_sesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to log out?"])},
    "txt_si_salir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, log out"])},
    "txt_no_seguir_navegando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, keep browsing"])},
    "txt_eliminar_mi_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete my account"])},
    "txt_deseas_eliminar_tu_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete your account? This action is irreversible."])},
    "txt_si_eliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete"])},
    "txt_no_seguir_aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, stay here"])},
    "txt_se_elimino_su_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been successfully deleted."])},
    "ext_su_usuario_ya_se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your user account was already deleted. "])}
  },
  "orderinfocomponet": {
    "txt_selecciona_tu_dispositivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your device"])},
    "txt_regresar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "txt_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
  },
  "itemproductocarrito": {
    "txt_ahorraste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You saved:"])}
  },
  "itemproductopagar": {
    "txt_cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity:"])}
  },
  "mobilefootercomponent": {
    "txt_ver_carrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Cart:"])}
  },
  "ordeinfocomponent": {
    "txt_entrega_estiamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Delivery:"])},
    "txt_puntos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
    "txt_repartidor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Person"])},
    "txt_ayudanos_a_mejorar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help us improve"])},
    "txt_calificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
    "txt_datos_del_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
    "txt_fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date:"])},
    "txt_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery:"])},
    "txt_titular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder:"])},
    "txt_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellphone:"])},
    "txt_comprobante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "txt_descargar_comprobante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download receipt"])},
    "txt_metodo_de_pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])}
  },
  "ordenstatuscomponent": {
    "txt_cocinando_tu_orden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing your order"])},
    "txt_listo_para_recojo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready for pickup"])},
    "txt_en_camino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the way"])},
    "txt_entregado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])}
  },
  "orderdatailcomponent": {
    "txt_elegiste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You chose:"])},
    "txt_descuento_double_dat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount:"])},
    "txt_costo_de_envio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Cost"])},
    "txt_bolsa_compostable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compostable Bag"])},
    "txt_descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "txt_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "applyjob": {
    "txt_volver_al_inicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Home"])},
    "txt_trabaja_con_nosotros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with Us"])},
    "txt_unete_a_nuestra_familia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our family"])},
    "txt_adjuntar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach"])},
    "txt_el_tamano_maximo_de_tu_archivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*The maximum size of your file should be 2 MB in PDF format"])},
    "txt_he_leido_y_acepto_los": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and accept the Terms and Conditions and Privacy Policy and Personal Data Processing"])},
    "txt_administrativo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative"])},
    "txt_azafatas_mozos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiters/ Waitresses"])},
    "txt_cocina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitchen"])},
    "txt_limpieza_y_mantenimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cleaning and Maintenance"])},
    "txt_call_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call center"])},
    "txt_por_favor_debe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach your CV."])},
    "txt_por_favor_espere_que_termine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait for the file upload to complete"])},
    "txt_debe_seleccionar_al_menos_trabajo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select at least one job position"])},
    "txt_debe_seleccionar_al_menos_gustaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select at least one location where you would like to work"])},
    "txt_debe_ingresar_un_numero_de_dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a valid ID number"])},
    "txt_debe_ingresar_un_numero_de_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a valid phone number"])},
    "txt_debe_ingresar_su_nombre_completo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter your full name"])},
    "txt_debe_aceptar_los_terminos_y_condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the terms and conditions"])},
    "txt_recibimos_tus_datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your data successfully. Thank you for your time and interest!"])},
    "txt_hubo_un_error_por_favor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error. Please contact us via WhatsApp"])}
  },
  "cambiarclave": {
    "txt_escribe_tu_nueva_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your new password"])},
    "txt_tu_nueva_contrasena_debe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Your new password must be at least 6 characters long."])},
    "txt_la_contrasena_debe_ser_igual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must be the same in both fields. Please verify."])},
    "txt_cambiar_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])}
  },
  "librodereclamaciones": {
    "txt_libro_de_reclamaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaints Book"])},
    "txt_conforme_a_lo_establecido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In accordance with the provisions of the Consumer Protection and Defense Code, we have a Complaints Book available for you to register your complaint or claim about any order or transaction made."])},
    "txt_fecha_de_registro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Date:"])},
    "txt_razon_social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name:"])},
    "txt_ruc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID:"])},
    "txt_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address:"])},
    "txt_identificacion_del_local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification of the establishment"])},
    "txt_identificacion_del_consumidor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification of the complaining consumer"])},
    "txt_soy_menor_de_edad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am under age"])},
    "txt_identificacion_del_bien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification of the contracted good"])},
    "txt_producto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "txt_servicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "txt_detalle_de_reclamacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint and Consumer Claim Details"])},
    "txt_reclamo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint"])},
    "txt_queja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim"])},
    "txt_reclamo_disconformidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLAINT: Disagreement related to products or services."])},
    "txt_queja_disconformidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLAIM: Disagreement not related to products or services, it is made to express dissatisfaction with customer service."])},
    "txt_la_formulacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filing a complaint does not prevent recourse to other dispute resolution mechanisms, nor is it a prerequisite for filing a complaint with INDECOPI."])},
    "txt_el_proveedor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The supplier must respond to the complaint within a maximum period of thirty (30) calendar days, which may be extended for an additional thirty (30) days, upon prior communication to the consumer."])},
    "txt_disconformidad_relacionada_a_los_productos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontent related to products or services"])},
    "txt_disconformidad_no_relacionada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontent not related to products or services"])},
    "txt_reclamo_enviado_con_exito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim sent successfully"])}
  },
  "paymentscreen": {
    "txt_carrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
    "txt_pagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "txt_modalidad_de_entrega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Method"])},
    "txt_cambiar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
    "txt_deseas_programar_tu_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to schedule your order?"])},
    "txt_local_de_consumo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption Location"])},
    "txt_comprobante_de_pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proof of Payment"])},
    "txt_boleta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "txt_boleta_electronica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic Receipt"])},
    "txt_factura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
    "txt_ingresa_tus_datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your details"])},
    "txt_comentarios_opcional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments (optional)"])},
    "txt_cupon_de_descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Coupon"])},
    "txt_resumen_del_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORDER SUMMARY"])},
    "txt_producto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "txt_costo_de_envio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Cost:"])},
    "txt_delivery_gratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Delivery"])},
    "txt_pagar_en_linea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online"])},
    "txt_pagar_en_efectivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay in Cash"])},
    "txt_pagar_con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with"])},
    "txt_debe_ingresar_una_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a shipping address"])},
    "txt_el_producto_no_esta_disponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product <strong>potato</strong> is not available. It has been removed from your cart."])},
    "txt_lo_sentimos_por_el_momento": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We’re sorry, but at the moment we do not have the <strong>", _interpolate(_named("modified")), "</strong> option for the <strong>", _interpolate(_named("productName")), "</strong> product. Please check your cart and update your preferences."])},
    "txt_se_actualizo_el_precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated <strong>potato</strong> <strong>price</strong>"])},
    "txt_venta_exitosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful sale. We will be notifying you of the status of your order!"])},
    "txt_compra_exitosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful purchase"])},
    "txt_se_descontara_el_valor_de": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The value of S/ ", _interpolate(_named("amount_discount")), " will be discounted."])},
    "txt_se_descontara_un_porcentaje_de": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A percentage of ", _interpolate(_named("porcentaje")), "% will be discounted based on the cart's value"])},
    "txt_se_descontara_el_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The shipping cost will be discounted "])},
    "txt_cupon_valido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Valid"])},
    "txt_debe_ingresar_un_codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to enter a code or coupon"])},
    "txt_el_local_no_ofrece": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Local ", _interpolate(_named("localName")), " doesn't offer ", _interpolate(_named("typeDeliveryName"))])},
    "txt_tipo_delivery_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type_delivery_name"))])},
    "txt_debe_ingresar_la_fecha_y_hora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to enter the date and time you want to have your order scheduled."])},
    "txt_debe_seleccionar_el_tipo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select the type of proof of payment you want."])},
    "txt_debe_seleccionar_un_metodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select a payment method"])},
    "txt_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "txt_en_linea_izipay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online izipay"])},
    "txt_comentario_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment:"])}
  },
  "reservas": {
    "txt_indique_el_local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicate the establishment:"])},
    "txt_indique_la_fecha_que_desee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicate the desired reservation date:"])},
    "txt_indique_la_cantidad_de_comensales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicate the number of diners"])},
    "txt_local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishment"])},
    "txt_fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "txt_cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "txt_escoja_el_horario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your preferred time slot:"])},
    "txt_dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "txt_mes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "txt_tarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afternoon"])},
    "txt_noche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evening"])},
    "txt_a_las": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At"])},
    "txt_hora_h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour h"])},
    "txt_para": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For"])},
    "txt_personas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
    "txt_elije_una_zona_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a preferred area:"])},
    "txt_solicitar_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request reservation"])},
    "txt_comensales_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diners:"])},
    "txt_para_terminar_con_el_proceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To complete the reservation process, proceed to make the payment of"])},
    "txt_monto_que_sera_descontado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount that will be deducted from your consumption"])},
    "txt_titulo_reserva_programada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title: Successful Scheduled Reservation"])},
    "txt_nombre_del_cliente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])},
    "txt_tu_reserva_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reservation at Company Name has been successfully scheduled."])},
    "txt_codigo_de_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Code:"])},
    "txt_nota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
    "txt_muestra_el_codigo_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show the QR code to identify your reservation and redeem your prepayment."])},
    "txt_le_recordamos_que_el_tiempo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We remind you that the tolerance time is a maximum of 20 minutes"])},
    "txt_politica_de_modificaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications and Cancellations Policy"])},
    "txt_desarrollador_por": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developed by"])}
  },
  "unsubscribed": {
    "txt_se_anulo_la_suscripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription was successfully canceled"])},
    "txt_dejaremos_de_enviarte_promociones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will stop sending you promotions, offers, benefits, and related communications."])}
  },
  "perfilmisgestionnotification": {
    "txt_gestiona_tus_notificaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your order status and advertising notifications."])},
    "txt_notificaciones_de_publicidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising notifications in the app"])},
    "txt_correos_con_promociones_exclusivas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails with exclusive promotions"])},
    "txt_whatsapp_de_estado_de_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp order status notifications"])},
    "txt_whatsapp_promocionales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotional WhatsApp messages"])}
  },
  "whatsAppPaymentScreen": {
    "txt_pago_en_linea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONLINE PAYMENT"])},
    "txt_resumen_del_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Summary"])}
  }
}